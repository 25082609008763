.settings_color {
    color: #9A5E9E;
}

.setting_display {
  background-color: #b783b7
}

.edit_display {
    width: 100%;
}

@keyframes draw-checkmark {
    from {
      stroke-dashoffset: 24;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  
  .animate-draw {
    stroke-dasharray: 24;
    stroke-dashoffset: 24;
    animation: draw-checkmark 1s ease forwards;
  }

  @keyframes drawX {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
      transform: rotate(45deg);
    }
    50% {
      opacity: 1;
      transform: rotate(45deg);
    }
    75% {
      opacity: 1;
      transform: rotate(-45deg);
    }
    100% {
      opacity: 0;
    }
  }

.animate-x {
  animation: drawX 2s ease-in-out;
}

.categoryBackground {
  background-color: #C8A2C8;
}

.categoryButton {
  background-color: #b783b7;
  color: #fff;
  padding: 9px;
  border: 1px solid #9A5E9E;
  transition: background-color 0.3s;
  width: 56%;
}

@media (min-width: 768px) {
  .categoryButton {
    width: 46%;
  }
}

.categoryButton:hover {
  background-color: #9A5E9E;
}

.allUploadsButton {
  background-color: #b783b7;
  color: #fff;
  padding-inline: 6px;
  border: 1px solid #9A5E9E;
  transition: background-color 0.3s;
  width: 30%;
  height: 35px;
  padding: 5px;
}

.allUploadsButton:hover {
  background-color: #9A5E9E;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .allUploadsButton {
    width: 20%;
  }
}

@media (min-width: 1025px) {
  .allUploadsButton {
    width: 10%;
  }
}

.viewAllCategoriesBackground {
  background: whitesmoke;
  /* height: max-content; */
  padding-top: 100px;
}

@media (min-width: 768px) {
  .viewAllCategoriesBackground {
    padding-top: 95px;
  }
}

.allCategories {
  font-size: 11px;
  font-weight: 900;
}